<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row dense class="mt-0">
          <v-col class="col-8">
            <h2 class="text-center pt-10">INVOICE</h2>
          </v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
            <p class="text-small mb-0">
              Riggott &amp; Co Limited<br />
              Unit X, Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Nottinghamshire, NG22 0NL<br />
              Tel: (01777) 872525 Fax: (01777) 872626<br />
              <span class="text-tiny">
                Email: info@riggott.co.uk Web: www.riggott.co.uk
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="col-8">
            <p style="min-height: 120px" class="text-mid text-heavy bordered pa-1">
              {{ consolidatedPayment.customerName }}<br />
              <span v-if="consolidatedPayment.addressOne">
                <span v-if="consolidatedPayment.addressOne">{{ consolidatedPayment.addressOne }}, <br /></span>
                <span v-if="consolidatedPayment.addressTwo">{{ consolidatedPayment.addressTwo }}, </span>
                <span v-if="consolidatedPayment.addressThree">{{ consolidatedPayment.addressThree }}, </span>
                <span v-if="consolidatedPayment.addressFour">{{ consolidatedPayment.addressFour }}, </span>
                <span v-if="consolidatedPayment.addressFive">{{ consolidatedPayment.addressFive }}, </span>
                <span v-if="consolidatedPayment.postcode"><br />{{ consolidatedPayment.postcode }}</span>
              </span>
            </p>
          </v-col>
          <v-col class="col-4">
            <div style="min-height: 120px" class="bordered pa-1">
              <v-row class="pa-0 ma-0">
                <v-col class="col-5 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0">
                    Reference:<br />
                    Certificate:<br />
                    Date/Tax Point:<br />
                    Order Ref:<br />
                    <strong>Account Ref:</strong><br />
                  </p>
                </v-col>
                <v-col class="col-7 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0 text-right">
                    {{ consolidatedPayment.reference }}<br />
                    {{ consolidatedPayment.certificate_code }}<br />
                    {{ consolidatedPayment.date | tinyDate }}<br />
                    {{ consolidatedPayment.order_reference }}<br />
                    <strong>{{ consolidatedPayment.account_reference }}</strong><br />
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div style="min-height: 137mm">
          <v-row dense>
            <v-col class="col-6 bordered no-right">
              <span class="text-mid text-heavy">
                Description
              </span>
            </v-col>
            <v-col class="col-2 bordered no-right">
              <span class="text-mid text-heavy">
                Qty
              </span>
            </v-col>
            <v-col class="col-2 bordered no-right">
              <span class="text-mid text-heavy">
                Rate
              </span>
            </v-col>
            <v-col class="col-2 text-right bordered">
              <span class="text-mid text-heavy">
                Value
              </span>
            </v-col>
          </v-row>
          <v-row dense v-for="(line, index) in consolidatedPayment.consolidatedPaymentLines" :key="index">
            <v-col class="col-6 pt-0 border-left">
              <span class="text-mid">{{ line.description }}</span>
            </v-col>
            <v-col class="col-2 pt-0 border-left">
              <span class="text-mid">{{ line.quantity }}</span>
            </v-col>
            <v-col class="col-2 pt-0 border-left">
              <span class="text-mid">{{ line.value | priceInPounds | currency }}</span>
            </v-col>
            <v-col class="col-2 pt-0 text-right border-left border-right">
              <span class="text-mid">{{ (line.quantity * line.value) | priceInPounds | currency }}</span>
            </v-col>
          </v-row>
          <v-row v-for="n in blankLines" :key="n" dense>
            <v-col class="col-6 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
            <v-col class="col-2 pt-0 text-right border-left border-right">&nbsp;</v-col>
          </v-row>
          <v-row dense>
            <v-col class="border-top"></v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="col-7">&nbsp;</v-col>
          <v-col class="col-5">
            <p class="text-mid bordered pa-1">
              <span class="text-heavy">Sub-total:</span>
              <span class="float-right text-heavy">
                {{ subTotal | priceInPounds | currency }}
              </span><br />
              <span class="text-heavy">VAT:</span>
              <span class="float-right text-heavy">
                {{ vat | priceInPounds | currency }}
              </span><br />
              <span class="text-heavy">Total:</span>
              <span class="float-right text-heavy">
                {{ total | priceInPounds | currency }}
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense class="pt-2">
          <v-col class="col-10 border-top pb-0">
            <p class="text-center text-mid mb-0">
              <span class="text-heavy">Payment terms:</span>
              Payment is due 30 days from date of invoice
              <span class="text-heavy">Bank:</span> NatWest<br />
              <span class="text-heavy">Sort Code:</span> 60-17-28
              <span class="text-heavy">a/c no:</span> 90117395
              &nbsp;&nbsp;&nbsp;We also accept payment by credit/debit card
            </p>
          </v-col>            
          <v-col class="col-2 border-top pb-0">
            <p class="text-center">
              <img width="200px" src="../../assets/payment_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0" dense>
          <v-col class="col-12 pt-0">
            <p class="text-center text-mid">
              Company registered in England Number 2985947
              VAT Registration Number GB 598 6171 83    UTR 74932 96911<br />
              <img width="700px" src="../../assets/riggott_accreditation_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesConsolidatedPaymentPrintView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      blankLines: 0,
      consolidatedPayment: {
        vat: false,
        consolidatedPaymentLines: [],
      },
      subTotal: 0,
      vat: 0,
      total: 0,
    };
  },
  watch: {
    consolidatedPayment: {
      deep: true,
      handler() {
        let subtotal = 0;
        for ( let i = 0; this.consolidatedPayment.consolidatedPaymentLines.length > i; i += 1) {
          const lineTotal = this.consolidatedPayment.consolidatedPaymentLines[i].quantity * this.consolidatedPayment.consolidatedPaymentLines[i].pounds;
          subtotal += lineTotal;
        }
        this.subTotal = (subtotal * 100);
        if (this.consolidatedPayment.vat === true) {
          this.vat = ((this.subTotal * 1.2) - this.subTotal);
        } else {
          this.vat = 0;
        }
        this.total = (this.vat + this.subTotal);
      },
    },
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    print() {
      window.print();
    },
    getConsolidatedPayment() {
      axios.get(`/consolidatedPayments/get/${this.$route.params.id}.json?token=${this.token}`)
        .then((response) => {
          this.consolidatedPayment = response.data.consolidatedPayment;  
          const lineNumber = response.data.consolidatedPayment.consolidatedPaymentLines.length;
          this.blankLines = 17 - lineNumber;        
        });
    },
  },
  mounted() {
    this.getConsolidatedPayment();
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
